body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  padding: 0.5em;
  margin: 0.5em;
}
h3 {
  border-bottom: 1px solid #212529;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#music-list .list-group-item:hover {
  cursor: pointer;
  background-color: #212529;
  color: white;
}
